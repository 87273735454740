<template>
    <h2>Loading</h2>
</template>

<script>
import { api } from '../../utils/requests';

export default {
    data() {
        return {
        }
    },
    created() {
        window.location.href = api.base_auth_url + api.native_auth_urls.register + "?next=" + api._construct_current_host()
    },
    computed: {
    },
    methods: {
    }
}
</script>
